import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Image from '@/components/page-components/image';
import { FeatureColumnsSection } from 'typings/pages';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  titleText: {
    marginTop: theme.spacing(1.5),
  },
}));

interface Props {
  data: FeatureColumnsSection
}

const defaultProps = {

};

const FeatureColumnsGroup = ({ data }:Props) => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        {data.features.map((feature) => (
          <Grid item xs={12} md={4} key={feature.id}>
            <Image media={feature.icon} width="auto" height="auto" />
            <Typography
              variant="h4"
              gutterBottom
              className={classes.titleText}
            >
              {feature.title}
            </Typography>
            <Typography>{feature.description}</Typography>
          </Grid>
        ))}
      </Grid>

    </Container>
  );
};
FeatureColumnsGroup.defaultProps = defaultProps as unknown as Partial<Props>;

export default FeatureColumnsGroup;
