import React from 'react';
import Markdown from 'react-markdown';

// MUI imports
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid } from '@material-ui/core';
import { HeroSection } from 'typings/pages';
import ButtonLink from '@/components/page-components/button-link';
import Image from '@/components/page-components/image';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  description: {
    marginBottom: theme.spacing(3),
  },
  heroImg: {
    filter: `drop-shadow(10px 20px 6px ${theme.palette.primary.main})`,
  },
  buttons: {
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(1.5),
    },
  },
}));

interface Props {
  data: HeroSection
}

const defaultProps = {
};

const Hero = ({ data }: Props) => {
  const classes = useStyles();
  return (
    <Container>
      {/* Left column for content */}
      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        className={classes.root}
      >
        <Grid item xs={12} md={6}>

          {/* Hero section label */}
          <Typography variant="overline" color="textSecondary">{data.label}</Typography>
          {/* Big title */}
          <Typography variant="h3" gutterBottom>{data.title}</Typography>
          {/* Description paragraph */}
          <Typography className={classes.description} gutterBottom>{data.description}</Typography>
          {/* Buttons row */}
          <div className={classes.buttons}>
            {data.buttons.map((button) => (
            // <ButtonLink
            //   button={button}
            //   appearance={getButtonAppearance(button.type, "light")}
            //   key={button.id}
            // />
              <ButtonLink
                href={button.url}
                key={button.id}
                appearance={button.type}
              >
                {button.text}
              </ButtonLink>
            ))}
          </div>
          {/* Small rich text */}
          <Typography>
            <Markdown>{data.smallTextWithLink as string}</Markdown>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Right column for the image */}
          <Image
            media={data.picture}
            width="100%"
            height="auto"
            className={classes.heroImg}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
Hero.defaultProps = defaultProps as unknown as Partial<Props>;
export default Hero;
