import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Container,
  Grid,
} from '@material-ui/core';

import { BlogPost } from 'typings/pages';
import { ArrowRightAlt } from '@material-ui/icons';
import ButtonLink from '@/components/page-components/button-link';
import BlogPostItem from '@/components/page-components/short-blog-post';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  heading: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  seeMoreBtn: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface Props {
  data: BlogPost[]
}

const defaultProps = {

};

const FeaturedBlogPosts = ({ data }: Props) => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Typography
        variant="h3"
        className={classes.heading}
        color="primary"
      >
        Insights from our blog
      </Typography>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        alignContent="center"
      >
        {data.map((item) => (
          <Grid key={item.id} item xs={12} md={4}>
            <BlogPostItem item={item} />
          </Grid>
        ))}
      </Grid>

      <div className={classes.seeMoreBtn}>
        <ButtonLink
          href="/blog/list/1"
          endIcon={<ArrowRightAlt />}
          appearance="primaryOutlined"
        >
          See more
        </ButtonLink>
      </div>
    </Container>
  );
};

FeaturedBlogPosts.defaultProps = defaultProps as unknown as Partial<Props>;

export default FeaturedBlogPosts;
