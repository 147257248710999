import React, { useReducer } from 'react';
import { motion, useAnimation } from 'framer-motion';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Container from '@material-ui/core/Container';
import { MagicFeaturesSection } from 'typings/pages';
import MagicFeatureElement from './magic-feature-element';
import MagicFeatureSecondaryImage from './magic-feature-secondary-image';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  animatedBackground: {
    display: 'block',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 10,
  },
  img: {
    width: '50%',
    position: 'sticky',
    top: 'calc(0.2 * 100vh)',
    willChange: 'transform',
    filter: `drop-shadow(10px 20px 10px ${theme.palette.primary.main})`,
  },
  contentGrid: {
    position: 'relative',
  },
  spacer: {
    minHeight: '15vh',
  },
}));

interface Props {
  data: MagicFeaturesSection
}

const defaultProps = {

};

const initialState = {
  activeImgIndex: 0,
};

type Action = {
  type: 'CHANGE_ACTIVE_INDEX'
  payload: number
};

function reducer(_: any, action: Action) {
  switch (action.type) {
    case 'CHANGE_ACTIVE_INDEX':
      return { activeImgIndex: action.payload };
    default:
      return { activeImgIndex: 0 };
  }
}

const MagicFeature = ({ data }: Props) => {
  const classes = useStyles();
  const controls = useAnimation();
  const [state, dispatch] = useReducer(reducer, initialState);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm')); // media query for SM screens

  return (
    <>
      <Container className={classes.root}>
        {!sm && (
        <div className={classes.animatedBackground}>
          <motion.div
            animate={controls}
            initial="left"
            transition={{ duration: 0.1, type: 'spring', stiffness: 110 }}
            className={classes.img}
            variants={{
              left: { transform: 'translate(0%) rotateY(15deg) rotateX(10deg)' },
              right: { transform: 'translate(90%) rotateY(-15deg) rotateX(10deg)' },
            }}
          >
            <img
              src={data.Background.url}
              width="100%"
              alt="bg"
            />
            {data.MagicFeature.map((item, index) => (
              <MagicFeatureSecondaryImage
                key={item.id}
                img={item.Image.url}
                index={index}
                state={state}
              />
            ))}
          </motion.div>
        </div>
        )}
        <div className={classes.contentGrid}>
          {data.MagicFeature.map((item, index) => (
            <MagicFeatureElement
              controls={controls}
              key={item.id}
              item={item}
              side={index % 2 === 0 ? 'left' : 'right'}
              index={index}
              dispatch={dispatch}
              bgImg={data.Background.url}
            />
          ))}
        </div>
      </Container>
      <div className={classes.spacer} />
    </>
  );
};

MagicFeature.defaultProps = defaultProps as unknown as Partial<Props>;

export default MagicFeature;
