import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { BlogPost } from 'typings/pages';
import {
  Typography,
  Card,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  CardContent,
  CardActions,
  CardMedia,
  Chip,
} from '@material-ui/core';

import { ArrowRightAlt } from '@material-ui/icons';
import ButtonLink from './button-link';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    height: '170px',
  },
  cardContent: {
    flexGrow: 1,
  },
  tags: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    marginBottom: theme.spacing(1),
  },
  divider: {
    // marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  item: BlogPost
}

const defaultProps = {

};

const BlogPostItem = ({ item }: Props) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>

      {/* header image */}
      <CardMedia
        className={classes.cardMedia}
        image={item.Metadata?.shareImage?.url}
      />

      {/* post content */}
      <CardContent className={classes.cardContent}>
        <Typography
          variant="h4"
          gutterBottom
        >
          {item.Title}
        </Typography>
        <Divider />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              {`${item.author?.firstname[0]}${item.author?.lastname[0]}`}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${item.author?.firstname} ${item.author?.lastname}`}
            secondary={(new Date(item.created_at)).toLocaleDateString()}
          />
        </ListItem>
        <Divider className={classes.divider} />
        <div className={classes.tags}>
          {item.tags.map((tag) => (
            <Chip key={tag.id} label={tag.Tag} color="primary" />
          ))}
        </div>
        <Typography color="textSecondary">
          {item.Metadata.metaDescription}
        </Typography>
      </CardContent>

      {/* actions */}
      <CardActions>
        <ButtonLink
          href={`/blog/${item.Slug}`}
          appearance="primaryOutlined"
          fullWidth
          endIcon={<ArrowRightAlt />}
        >
          Read
        </ButtonLink>
      </CardActions>
    </Card>
  );
};

BlogPostItem.defaultProps = defaultProps as unknown as Partial<Props>;

export default BlogPostItem;
