import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { LargeVideoSection } from 'typings/pages';
import Video from '@/components/page-components/video';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    textAlign: 'center',
  },
  videoWrapper: {
    boxShadow: theme.shadows[20],
    width: '100%',
  },
  spacer: {
    minHeight: theme.spacing(5),
  },
  video: {
    width: '100%',
    height: '100%',
  },
}));

interface Props {
  data: LargeVideoSection
}
const defaultProps = {

};

const LargeVideo = ({ data }: Props) => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Container>
        <Typography variant="h3" gutterBottom>{data.title}</Typography>
        <Typography gutterBottom>{data.description}</Typography>
        <div className={classes.spacer} />
        {/* Video wrapper */}
        <div className={classes.videoWrapper}>
          <Video
            media={data.video}
            poster={data.poster}
            className={classes.video}
          />
        </div>
      </Container>
    </section>
  );
};

LargeVideo.defaultProps = defaultProps as unknown as Partial<Props>;

export default LargeVideo;
