import React from 'react';
import useAuth from '../../hooks/useAuth';
import LoadingScreen from '../ui-components/LoadingScreen';

interface Props {
  children: React.ReactNode
}

const TryToAuthorizeView = ({ children }: Props) => {
  const auth = useAuth({ redirect: false });
  if (auth === 'LOADING_AND_BLOCKING') {
    return <LoadingScreen />;
  }
  return <>{children}</>;
};

export default TryToAuthorizeView;
