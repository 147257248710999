import React from 'react';
import { PricingSection } from 'typings/pages';

interface Props {
  data: PricingSection
}

const defaultProps = {

};

const Pricing = ({ data }:Props) => (
  <div className="container py-12">
    <h1 className="text-4xl text-center">{data.title}</h1>
    <div className="flex flex-col lg:flex-row gap-4 lg:justify-center mt-6" />
  </div>
);

Pricing.defaultProps = defaultProps as unknown as Partial<Props>;

export default Pricing;
