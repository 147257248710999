import React, { ReactNode } from 'react';
import Link from 'next/link';

interface Props{
  link:{
    id: number;
    url: string;
    newTab: boolean;
    text: string;
  }|undefined
  children: ReactNode
}

const CustomLink = ({ link, children }: Props) => {
  const isInternalLink = link?.url?.startsWith('/');

  // For internal links, use the Next.js Link component
  if (isInternalLink) {
    return (
      <Link href="/[[...slug]]" as={link?.url}>
        <a href="/[[...slug]]">
          {children}
        </a>
      </Link>
    );
  }

  // Plain <a> tags for external links
  return (
    // disabled because eslint doesn't understand the conditional
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={link?.url}
      // Change target and rel attributes is newTab is turned on
      target={link?.newTab ? '_blank' : '_self'}
      rel={link?.newTab ? 'noopener noreferrer' : ''}
    >
      {children}
    </a>
  );
};
export default CustomLink;
