import { unwrapResult } from '@reduxjs/toolkit';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { restoreToken } from '../features/auth/api/slice';
import { RootState } from '../store/rootReducer';
import { AppDispatch } from '../store/store';

const useAuth = ({ redirect }: {redirect: boolean}) => {
  const dispatch = useDispatch() as AppDispatch;
  const auth = useSelector((state: RootState) => state.auth);
  const [cookies] = useCookies();
  const { asPath, push, query } = useRouter();

  // restore token if it hasn't been restored
  useEffect(() => {
    if (!auth.token && !auth.restoreAttempted) {
      // get token from localStorage
      const token = localStorage.getItem('token');
      dispatch(restoreToken({ token: token || cookies.token })).then(unwrapResult).then(
        () => {},
      ).catch(() => !query.next && redirect && push(`/user/login?next=${asPath}`));
    }
  }, [auth.token]);

  // return auth state
  const [authenticatorState, setAuthenticatorState] = useState('LOADING_AND_BLOCKING');
  useEffect(() => {
    if (auth.token) {
      setAuthenticatorState('AUTHORIZED');
    } else if (auth.isLoadingAndBlocking || !auth.restoreAttempted) {
      setAuthenticatorState('LOADING_AND_BLOCKING');
    } else if (auth.isLoading) {
      setAuthenticatorState('LOADING');
    } else {
      setAuthenticatorState('UNAUTHORIZED');
    }
  }, [auth]);

  return authenticatorState;
};

export default useAuth;
