import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import { PageHeaderSection } from 'typings/pages';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    paddingBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.up('md')]: {
      minHeight: '30vh',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '15vh',
    },
  },
}));

interface Props {
  data: PageHeaderSection
}

const defaultProps = {

};

const PageHeader = ({ data }: Props) => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Container>
        <Typography variant="h1" color="inherit">
          {data.title}
        </Typography>
      </Container>
    </section>
  );
};

PageHeader.defaultProps = defaultProps as unknown as Partial<Props>;

export default PageHeader;
