import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Image from '@/components/page-components/image';
import Video from '@/components/page-components/video';
import CustomLink from '@/components/page-components/custom-link';
import { FeatureRowsSection } from 'typings/pages';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  grid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  descriptionText: {
    marginBottom: theme.spacing(3),
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:after': {
      backgroundImage: 'url(data:image/svgxml;charset=utf-8,%3Csvg%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%206a.5.5%200%200%200%200%201V6zM12.854.646a.5.5%200%200%200-.708.708l.708-.708zM18%206.5l.354.354a.5.5%200%200%200%200-.708L18%206.5zm-5.854%205.146a.5.5%200%200%200%20.708.708l-.708-.708zM1%207h16.5V6H1v1zm16.646-.854l-5.5%205.5.708.708%205.5-5.5-.708-.708zm-5.5-4.792l2.75%202.75.708-.708-2.75-2.75-.708.708zm2.75%202.75l2.75%202.75.708-.708-2.75-2.75-.708.708z%22%20fill%3D%22%231264A3%22%2F%3E%3C%2Fsvg%3E)',
      content: '""',
      width: '19px',
      height: '13px',
      display: 'inline-block',
      marginLeft: theme.spacing(1.5),
    },
  },
  video: {
    width: '100%',
    height: 'auto',
  },
}));
interface Props {
  data: FeatureRowsSection
}

const defaultProps = {

};

const FeatureRowsGroup = ({ data }:Props) => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      {data.features.map((feature, index) => (
        <Grid
          key={feature.id}
          container
          spacing={1}
          direction={index % 2 === 0 ? 'row' : 'row-reverse'}
          justify="center"
          alignItems="center"
          alignContent="center"
          className={classes.grid}
        >
          {/* Text section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h3" gutterBottom>{feature.title}</Typography>
            <Typography className={classes.descriptionText}>{feature.description}</Typography>
            <CustomLink link={feature.link}>
              <div className={classes.link}>
                {feature.link?.text}
              </div>
            </CustomLink>
          </Grid>
          {/* Media section */}
          <Grid item xs={12} md={6}>
            {/* Images */}
            {feature.media?.mime.startsWith('image') && (
            <Image media={feature.media} width="100%" height="auto" />
            )}
            {/* Videos */}
            {feature.media?.mime.startsWith('video') && (
            <Video
              media={feature.media}
              className={classes.video}
              autoPlay
              controls={false}
            />
            )}
          </Grid>
        </Grid>
      ))}
    </Container>
  );
};
FeatureRowsGroup.defaultProps = defaultProps as unknown as Partial<Props>;

export default FeatureRowsGroup;
