import React from 'react';
import getStrapiMedia from '@/features/pages/utils/media';
import { ImageType } from 'typings/pages';

interface Props{
  media: ImageType
  poster: ImageType
  className: string
  controls?: boolean
  autoPlay?: boolean
}

const defaultProps = {
  controls: true,
  autoplay: false,
};

const Video = ({
  media,
  poster,
  className,
  controls = true,
  autoPlay = false,
}:Props) => {
  const fullVideoUrl = getStrapiMedia(media.url);
  const fullPosterUrl = getStrapiMedia(poster?.url);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      className={className}
      poster={fullPosterUrl}
      controls={controls}
      autoPlay={autoPlay}
    >
      <source src={fullVideoUrl} type={media.mime} />
    </video>
  );
};
Video.defaultProps = defaultProps as unknown as Partial<Props>;
export default Video;
