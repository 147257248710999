import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import ButtonLink from '@/components/page-components/button-link';
import { BottomActionsSection } from 'typings/pages';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    minHeight: '15rem',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  button: {
    width: '200px',
    margin: theme.spacing(2),
  },
}));
interface Props {
  data: BottomActionsSection
}

const defaultProps = {

};

const BottomActions = ({ data }:Props) => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Typography color="inherit" variant="h3">{data.title}</Typography>
      {/* Buttons row */}
      <div className={classes.buttons}>
        {data.buttons.map((button) => (
          <div key={button.id} className={classes.button}>
            <ButtonLink
              href={button.url}
              appearance={button.type}
              fullWidth
            >
              {button.text}
            </ButtonLink>
          </div>
        // <ButtonLink
        //   button={button}
        //   appearance={getButtonAppearance(button.type, "dark")}
        //   key={button.id}
        // />
        ))}
      </div>
    </section>
  );
};

BottomActions.defaultProps = defaultProps as unknown as Partial<Props>;

export default BottomActions;
