import React from 'react';
import getStrapiMedia from '@/features/pages/utils/media';
import { ImageType } from 'typings/pages';

interface Props {
  height: string
  width: string
  media: ImageType
  className?: string
}

const defaultProps = {
  className: null,
};

const Image = ({
  media, height, width, className,
}: Props) => {
  const fullUrl = getStrapiMedia(media?.url);

  return (
    <img
      src={fullUrl}
      alt={media?.alternativeText || ''}
      height={height}
      width={width}
      className={className}
    />
  );
};

Image.defaultProps = defaultProps as unknown as Partial<Props>;

export default Image;
