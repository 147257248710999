import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { motion, useAnimation } from 'framer-motion';

const useStyles = makeStyles((theme) => ({
  secondaryImg: {
    position: 'absolute',
    bottom: '-10%',
    right: 0,
    height: '80%',
    filter: `drop-shadow(10px 20px 10px ${theme.palette.primary.main})`,
  },
}));

interface Props {
  img: string
  index: number
  state: {
    activeImgIndex: number
  }
}

const defaultProps = {

};

const MagicFeatureSecondaryImage = ({ img, index, state }: Props) => {
  const classes = useStyles();
  const controls = useAnimation();

  useEffect(() => {
    if (state.activeImgIndex === index) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [state]);
  return (
    <>
      <motion.img
        src={img}
        width="80%"
        height="auto"
        alt="bg"
        animate={controls}
        transition={{ duration: 0.1 }}
        initial={index === 0 ? 'visible' : 'hidden'}
        className={classes.secondaryImg}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
      />
    </>
  );
};

MagicFeatureSecondaryImage.defaultProps = defaultProps as unknown as Partial<Props>;

export default MagicFeatureSecondaryImage;
