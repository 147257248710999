import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'next-i18next';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { motion } from 'framer-motion';
import useWrap from '@/hooks/useWrap';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import Spacer from '@/components/ui-components/Spacer';
import { Background } from '@/components/ui-components/SVGBackground';
import useInterval from '@/hooks/useInterval';
import ButtonLink from '@/components/links/ButtonLink';
import Typography from '../components/Typography';
import Button from '../components/Button';

const ITEMS = [
  {
    key: 'commitments',
    photo: '/cta/commitments.png',
    photoWidth: 958,
    photoHeight: 699,
  },
  {
    key: 'info',
    photo: '/cta/info.png',
    photoWidth: 1886,
    photoHeight: 849,
  },
  {
    key: 'financials',
    photo: '/cta/financials.png',
    photoWidth: 954,
    photoHeight: 745,
  },
] as const;

const MARGIN_BETWEEN_STEPS = 100;
const PHOTO_ROLL_HEIGHT = 400;
const PHOTO_ROLL_HEIGHT_MOBILE = 240;
const AUTOMATIC_PAGINATION_INTERVAL = 5000;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[10],
    overflow: 'hidden',
  },
  photoRoll: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    height: PHOTO_ROLL_HEIGHT * 1.1,
    [theme.breakpoints.down('sm')]: {
      height: PHOTO_ROLL_HEIGHT_MOBILE * 1.1,
    },
    position: 'relative',
  },
  photoRollItem: {
    // flex: '0 1 100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: PHOTO_ROLL_HEIGHT * 1.1,
    [theme.breakpoints.down('sm')]: {
      minHeight: PHOTO_ROLL_HEIGHT_MOBILE * 1.1,
    },
    marginBottom: MARGIN_BETWEEN_STEPS,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  photo: {
    borderRadius: theme.shape.borderRadius,
    // boxShadow: theme.shadows[10],
  },
  ctaBox: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
  },
  textRoll: {
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
  },
  textRollItem: {
    flex: '1 0 100%',
    // display: 'flex',
    marginRight: MARGIN_BETWEEN_STEPS,
  },
  gridItem: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(3),
    },
  },
  svgBg: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: 'scale(-1,-1)',
    // [theme.breakpoints.up('sm')]: {
    //   clipPath: 'polygon(0% 40%, 0 100%, 15% 100%)',
    // },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    // [theme.breakpoints.up('md')]: {
    //   clipPath: 'polygon(0% 50%, 0 100%, 25% 100%)',
    // },
    [theme.breakpoints.up('sm')]: {
      // clipPath (X -> 0, Y -> ??) (X -> 0, Y -> 100%) (X -> ??, Y -> 100%)
    //   clipPath: 'polygon(0% 45%, 0 100%, 27% 100%)',
      clipPath: 'polygon(0% 38%, 0 100%, 34% 100%)',
    },
  },
  signupBtn: {
    minWidth: 300,
  },
  cta: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopColor: theme.palette.greyScale.light,
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    paddingTop: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
  },
}));

interface Props {

}

const defaultProps = {

};

const Features = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedItem, setSelectedItem] = useState(0);
  const handlePagination = (dir: 1 | -1) => {
    const newPage = selectedItem + dir;
    setSelectedItem(useWrap(0, ITEMS.length, newPage));
  };

  const textRef = useRef<HTMLDivElement | null>(null);
  const photoRef = useRef<HTMLDivElement | null>(null);
  const [offset, setOffset] = useState(0);
  // const [photoOffset, setPhotoOffset] = useState(0);
  const [photoAnimation, setPhotoAnimation] = useState(ITEMS.map((item, index) => {
    switch (index) {
      case 0:
        return ({
          scale: 0.9,
          rotate: 0,
          x: 0,
        });
      case 1:
        return ({
          rotate: 15,
          x: 50,
          scale: 0.7,
          filter: 'grayscale(50%)',
        });
      case ITEMS.length - 1:
        return ({
          rotate: -15,
          x: -50,
          scale: 0.7,
          filter: 'grayscale(50%)',
        });
      default:
        return ({
          scale: 0.9,
          rotate: 0,
          x: 0,
        });
    }
  }));
  useEffect(() => {
    setOffset(selectedItem
      * ((textRef.current?.offsetWidth || 0) + MARGIN_BETWEEN_STEPS) * (-1));
    // setPhotoOffset(selectedItem
    //   * ((photoRef.current?.offsetHeight || 0) + MARGIN_BETWEEN_STEPS) * (-1));
    setPhotoAnimation(ITEMS.map((item, index) => {
      switch (index) {
        case selectedItem:
          return ({
            scale: 0.9,
            rotate: 0,
            x: 0,
          });
        case useWrap(0, ITEMS.length, selectedItem + 1):
          return ({
            rotate: 15,
            x: 50,
            scale: 0.7,
            filter: 'grayscale(50%)',
          });
        case useWrap(0, ITEMS.length, selectedItem - 1):
          return ({
            rotate: -15,
            x: -50,
            scale: 0.7,
            filter: 'grayscale(50%)',
          });
        default:
          return ({
            scale: 0.9,
            rotate: 0,
            x: 0,
          });
      }
    }));
  }, [selectedItem, textRef.current]);

  useInterval(() => {
    handlePagination(1);
  }, AUTOMATIC_PAGINATION_INTERVAL);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.svgBg}>
          <Background />
        </div>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={12} md={6}>
            <div className={classes.photoRoll} ref={photoRef}>
              {ITEMS.map((item, index) => (
                <motion.div
                  key={item.key}
                  className={classes.photoRollItem}
                  style={{
                    zIndex: index === selectedItem ? 100 : 1,
                  }}
                  // animate={{ y: photoOffset }}
                  animate={photoAnimation[index]}
                  // whileHover={{ scale: index === selectedItem && 1.0 }}
                >
                  <Image
                    src={item.photo}
                    width={(isMobile
                      ? PHOTO_ROLL_HEIGHT_MOBILE : PHOTO_ROLL_HEIGHT)
                      * (item.photoWidth / item.photoHeight)}
                    height={isMobile ? PHOTO_ROLL_HEIGHT_MOBILE : PHOTO_ROLL_HEIGHT}
                    className={classes.photo}
                  />
                </motion.div>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <div className={classes.textRoll}>
              <Typography variant="title" color="inherit">{t('signup_cta.title')}</Typography>
            </div>
            <Spacer spacing={isMobile ? 0.5 : 1.25} />
            <div className={classes.textRoll} ref={textRef}>
              {ITEMS.map((item) => (
                <motion.div
                  key={item.key}
                  className={classes.textRollItem}
                  animate={{
                    x: offset,
                  }}
                >
                  <Typography variant="base" color="inherit">{t(`signup_cta.descriptions.${item.key}` as const)}</Typography>
                </motion.div>
              ))}
            </div>
            <Spacer spacing={2} />
            <Button
              text={t('signup_cta.discover_more')}
              onClick={() => handlePagination(1)}
              endIcon={<ArrowForwardOutlinedIcon />}
            />
          </Grid>
        </Grid>

        {/* cta */}
        <div className={classes.cta}>
          <Typography variant="small2" color="inherit">{t('signup_cta.description')}</Typography>
          <Spacer spacing={2} />
          <ButtonLink
            href="/user/signup"
            color="secondary"
            variant="contained"
            endIcon={<ArrowForwardOutlinedIcon />}
            className={classes.signupBtn}
            fullWidth={isMobile}
          >
            {t('signup_cta.sign_up')}
          </ButtonLink>
        </div>
      </div>
    </>
  );
};

Features.defaultProps = defaultProps as unknown as Partial<Props>;

export default Features;
