import React, { useState } from 'react';
import clsx from 'clsx';

import { makeStyles, lighten } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Image from '@/components/page-components/image';
import { TestimonialsSection } from 'typings/pages';
// import CustomLink from '@/components/page-components/custom-link';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
  },
  section: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.9),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    textAlign: 'center',
  },
  coverImg: {
    flexShrink: 0,
    width: '33%',
  },
  testimonalDiv: {
    display: 'flex',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'left',
  },
  quote: {
    marginBottom: theme.spacing(4),
  },
  logoImg: {
    marginBottom: theme.spacing(6),
  },
  authorName: {
    fontWeight: theme.typography.fontWeightBold,
  },
  authorTitle: {
    marginBottom: theme.spacing(4),
  },
  roundedBtn: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    borderRadius: '50%',
  },
  roundedBtnInactive: {
    backgroundColor: theme.palette.action.disabled,
  },
  roundedBtnActive: {
    backgroundColor: theme.palette.primary.main,
  },
  logoDiv: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(6),
    '& > *': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  spacer: {
    minHeight: theme.spacing(4),
  },
  testimonialSelectionDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  logoBottomImg: {

  },
}));
interface Props{
  data: TestimonialsSection
}

const defaultProps = {

};

const TestimonialsGroup = ({ data }:Props) => {
  // Only show one testimonial at a time
  const [selectedTestimonialIndex, setSelectedTestimonialIndex] = useState(0);
  const selectedTestimonial = data.testimonials[selectedTestimonialIndex];

  const classes = useStyles();

  return (
    <section className={classes.section}>
      <Typography variant="h3" gutterBottom>{data.title}</Typography>
      <Typography color="textSecondary" gutterBottom>{data.description}</Typography>
      <div className={classes.spacer} />
      <Container>
        <Card className={classes.card}>
          <Image
            media={selectedTestimonial.picture}
            className={classes.coverImg}
          />
          <div className={classes.testimonalDiv}>
            <div>
              <Image
                media={selectedTestimonial.logo}
                className={classes.logoImg}
                height="auto"
                width="20%"
              />
              <Typography color="textSecondary" className={classes.quote}>
                &quot;
                {selectedTestimonial.text}
                &quot;
              </Typography>
              <Typography className={classes.authorName}>
                {selectedTestimonial.authorName}
              </Typography>
              <Typography className={classes.authorTitle}>
                {selectedTestimonial.authorTitle}
              </Typography>
            </div>
          </div>
        </Card>
      </Container>
      {/* Change selected testimonial (only if there is more than one) */}
      {data.testimonials.length > 1 && (
        <div className={classes.testimonialSelectionDiv}>
          {data.testimonials.map((testimonial, index) => (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <button
              onClick={() => setSelectedTestimonialIndex(index)}
              className={clsx(classes.roundedBtn,
                index === selectedTestimonialIndex
                  ? classes.roundedBtnActive : classes.roundedBtnInactive)}
              type="button"
              key={testimonial.id}
            />
          ))}
        </div>
      )}
      {/* Logos list */}
      <div className={classes.logoDiv}>
        {data.logos.map((logo) => (
          <Image
            media={logo}
            className={classes.logoBottomImg}
            key={logo.id}
            width="160px"
            height="auto"
          />
        ))}
      </div>
    </section>
  );
};

TestimonialsGroup.defaultProps = defaultProps as unknown as Partial<Props>;

export default TestimonialsGroup;
