import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

// MUI components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// MUI icons
import BeenhereIcon from '@material-ui/icons/Beenhere';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RoadmapSection } from 'typings/pages';

interface StyleProps {
  rmType: 'shipped' | 'development' | 'planned' | null
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  heading: {
    textAlign: 'center',
    padding: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  cardheading: {
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  badge: {
    color: (props: StyleProps) => {
      switch (props.rmType) {
        case 'shipped':
          return theme.palette.positive.main;
        case 'development':
          return theme.palette.medium.main;
        default:
          return theme.palette.primary.main;
      }
    },
  },
  secondaryHeading: {
    color: theme.palette.secondary.light,
  },
  headerText: {
    color: theme.palette.secondary.light,
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
}));

interface Props {
  data: RoadmapSection
}

const defaultProps = {

};

const Roadmap = ({ data }: Props) => {
  const classes = useStyles({ rmType: data.type });

  const renderHeading = (param:'shipped' | 'development' | 'planned' | null) => {
    switch (param) {
      case 'shipped':
        return 'Already available';
      case 'development':
        return 'Shipping soon';
      case 'planned':
        return 'In the works';
      default:
        return 'Already shipped';
    }
  };

  return (
    <Container className={classes.root}>
      {/* today's features */}
      <Typography className={classes.heading} gutterBottom variant="h4" component="h2">
        {renderHeading(data.type)}
      </Typography>

      {data.RoadmapItem.map((item) => (
        <ExpansionPanel key={item.id}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <ListItem>
              <ListItemIcon>
                <BeenhereIcon className={classes.badge} />
              </ListItemIcon>
              <ListItemText
                primary={(
                  <Typography variant="h6" className={classes.cardheading}>
                    {item.Title}
                  </Typography>
                        )}
                secondary={(
                  <Typography variant="subtitle1" className={classes.secondaryHeading}>
                    {item.ShippingDate}
                  </Typography>
                        )}
              />
            </ListItem>

          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              {item.Description}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </Container>
  );
};

Roadmap.defaultProps = defaultProps as unknown as Partial<Props>;

export default Roadmap;
