import React, { Dispatch, useEffect } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Grid, Typography } from '@material-ui/core';
import useIsInViewport from 'use-is-in-viewport';
import { AnimationControls } from 'framer-motion';
import { MagicFeature } from 'typings/pages';
import useScrollDirection from '@/hooks/useScrollDirection';

interface StyleProps {
  side: 'left' | 'right'
  index: number
}

const useStyles = makeStyles((theme) => ({
  contentRow: {
    [theme.breakpoints.up('md')]: {
      minHeight: '130vh',
      marginTop: (props: StyleProps) => ((props.index === 0) ? '-20vh' : '0vh'),
    },
  },
  contentElem: {
    paddingLeft: (props: StyleProps) => ((props.side === 'right') ? 0 : theme.spacing(10)),
  },
  imgGrid: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
  },
  img: {
    width: '100%',
    filter: `drop-shadow(10px 20px 10px ${theme.palette.primary.main})`,
    position: 'relative',
  },
  secondaryImg: {
    position: 'absolute',
    bottom: '-10%',
    right: 0,
    height: '80%',
    filter: `drop-shadow(10px 20px 10px ${theme.palette.primary.main})`,
  },
}));

type Action = {
  type: 'CHANGE_ACTIVE_INDEX'
  payload: number
};

interface Props {
  side: 'left' | 'right'
  controls: AnimationControls
  item: MagicFeature
  index: number
  bgImg: string
  dispatch: Dispatch<Action>
}

const defaultProps = {

};

const MagicFeatureElement = ({
  item, controls, side, dispatch, index, bgImg,
}: Props) => {
  const classes = useStyles({ side, index });
  const [isInViewportDown, targetRefDown] = useIsInViewport();
  const [isInViewportUp, targetRefUp] = useIsInViewport();
  const scrollDirection = useScrollDirection({
    initialDirection: 'down',
    thresholdPixels: 3,
    off: false,
  });

  useEffect(() => {
    if (isInViewportDown && scrollDirection === 'down') {
      controls.start(side);
      dispatch({ type: 'CHANGE_ACTIVE_INDEX', payload: index });
    } else if (isInViewportUp && scrollDirection === 'up') {
      controls.start(side);
      dispatch({ type: 'CHANGE_ACTIVE_INDEX', payload: index });
    }
  }, [controls, isInViewportDown, isInViewportUp]);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm')); // media query for SM screens

  return (
    <>
      <Grid
        container
        direction="row"
        justify={side === 'right' ? 'flex-start' : 'flex-end'}
        alignItems="center"
        alignContent="center"
        className={classes.contentRow}
      >
        {sm && (
        <Grid item xs={12} className={classes.imgGrid}>
          <div
            className={classes.img}
          >
            <img
              src={bgImg}
              width="100%"
              alt="bg"
            />
            <img
              src={item.Image.url}
              width="80%"
              alt="bg"
              className={classes.secondaryImg}
            />
          </div>
        </Grid>
        )}
        <Grid item xs={12} md={4} className={!sm ? classes.contentElem : undefined}>
          <Typography variant="h3" ref={targetRefDown} gutterBottom>
            {item.Title}
          </Typography>
          <Typography ref={targetRefUp} color="textSecondary">
            {item.Description}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

MagicFeatureElement.defaultProps = defaultProps as unknown as Partial<Props>;

export default MagicFeatureElement;
