import React, { ReactNode } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import BottomFooter from '@/components/footer/BottomFooter';
import AppBar from '@/components/navigation/AppBar';

const useStyles = makeStyles((theme) => ({
  main: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '56px',
    },
  },
  flex: {
    flexGrow: 1,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
}));

interface Props{
  children: ReactNode
}

const Layout = ({ children }:Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* Aligned to the top */}
      <div className={classes.flex}>
        {/* <AppBarLandingPage

        /> */}
        <AppBar />
        <div className={classes.main}>{children}</div>
      </div>
      {/* Aligned to the bottom */}
      <BottomFooter />
    </div>
  );
};

export default Layout;
