import React from 'react';
import ErrorPage from 'next/error';
import { useRouter } from 'next/dist/client/router';
import {
  BlogPost,
  Metadata,
  Page,
  Section,
} from 'typings/pages';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getStrapiURL, getPageData } from '@/features/pages/utils/api';
import Sections from '@/features/pages/sections';
import Seo from '@/components/page-components/seo';
import Layout from '@/features/pages/layout';
import useAuth from '@/hooks/useAuth';
import withAuthTry from '@/hoc/withAuthTry';
import Features from '@/features/markets/offering-details/features';
import { Container } from '@material-ui/core';
import global from '../.global.json';

// The file is called [[...slug]].js because we're using Next's
// optional catch all routes feature. See the related docs:
// https://nextjs.org/docs/routing/dynamic-routes#optional-catch-all-routes

interface DynamicPageProps {
  sections: Section[]
  metadata: Metadata
  preview: boolean
  featuredBlogEntries: BlogPost[]
}

const DynamicPage = ({
  sections, metadata, preview, featuredBlogEntries,
}: DynamicPageProps) => {
  const router = useRouter();

  // Check if the required data was provided
  if (!router.isFallback && !sections?.length) {
    return <ErrorPage statusCode={404} />;
  }

  // Loading screen (only possible in preview mode)
  if (router.isFallback) {
    return <div className="container">Loading...</div>;
  }

  useAuth({ redirect: false });

  return (
    <Layout>
      {/* Add meta tags for SEO */}
      <Seo metadata={metadata} />

      {/* Display content sections */}
      <Sections sections={sections} preview={preview} featuredBlogEntries={featuredBlogEntries} />

      {/* display blog entries */}
      {/* {featuredBlogEntries && featuredBlogEntries.length > 0
        && <FeaturedBlogPosts items={featuredBlogEntries} />} */}
      <Container>
        <Features />
      </Container>
    </Layout>
  );
};

export async function getStaticPaths() {
  // Get all pages from Strapi
  // eslint-disable-next-line no-undef
  const pages: Page[] = await (await fetch(getStrapiURL('pages'))).json();
  const paths = pages.map((page) => {
    // Decompose the slug that was saved in Strapi
    const slugArray = page.slug.split('__');
    return {
      params: { slug: slugArray },
    };
  });
  return { paths, fallback: true };
}

interface Props{
  params:{
    slug?: string[]
  }
  preview: boolean|null
  locale: string
}

export async function getStaticProps({ params, locale, preview = null }: Props) {
  // Find the page data for the current slug
  let chainedSlugs;
  if (params === {} || !params.slug) {
    // To get the homepage, find the only page where slug is an empty string
    chainedSlugs = '';
  } else {
    // Otherwise find a page with a matching slug
    // Recompose the slug that was saved in Strapi
    chainedSlugs = params.slug.join('__');
  }

  // Fetch pages. Include drafts if preview mode is on
  // const pageData = await getPageData(chainedSlugs, preview);
  const pageData = await getPageData(chainedSlugs);

  if (pageData == null) {
    // Giving the page no props will trigger a 404 page
    return { props: {} };
  }

  // We have the required page data, pass it to the page component
  const { contentSections, metadata } = pageData;

  // get featured blog entries
  // eslint-disable-next-line no-undef
  const blogData: BlogPost[] = await (await fetch(getStrapiURL('blog-posts'))).json() || [];

  const featuredBlogEntries = blogData?.filter((x) => x.featured === true).slice(0, 3) || [];

  return {
    props: {
      global,
      ...await serverSideTranslations(locale, ['common', 'users', 'community', 'dashboard', 'settings', 'markets', 'feed', 'components', 'notifications']),
      preview,
      sections: contentSections,
      metadata,
      featuredBlogEntries,
    },
  };
}

export default withAuthTry(DynamicPage);
