import React from 'react';
import { useRouter } from 'next/router';
import { makeStyles } from '@material-ui/core/styles';
import Hero from '@/components/page-sections/hero';
import LargeVideo from '@/components/page-sections/large-video';
import FeatureColumnsGroup from '@/components/page-sections/feature-columns-group';
import FeatureRowsGroup from '@/components/page-sections/feature-rows-group';
import BottomActions from '@/components/page-sections/bottom-actions';

import {
  BlogPost,
  Section as SectionType,
} from 'typings/pages';
import TestimonialsGroup from '@/components/page-sections/testimonials-group';
import RichText from '@/components/page-sections/rich-text';
import Pricing from '@/components/page-sections/pricing';
import MagicFeature from '@/components/page-sections/magic-feature';
import FAQ from '@/components/page-sections/FAQ';
import Roadmap from '@/components/page-sections/roadmap';
import FeaturedBlogPosts from '@/components/page-sections/FeaturedBlogPosts';
import PageHeader from '@/components/page-components/page-header';

// Map Strapi sections to section components
const sectionComponents = {
  'sections.hero': Hero,
  'sections.large-video': LargeVideo,
  'sections.feature-columns-group': FeatureColumnsGroup,
  'sections.feature-rows-group': FeatureRowsGroup,
  'sections.bottom-actions': BottomActions,
  'sections.testimonials-group': TestimonialsGroup,
  'sections.rich-text': RichText,
  'sections.pricing': Pricing,
  'sections.magic-features': MagicFeature,
  'sections.faq': FAQ,
  'sections.roadmap': Roadmap,
  'sections.featured-blog-entries': FeaturedBlogPosts,
  'sections.page-header': PageHeader,
};

interface Props {
  sectionData: SectionType
  featuredBlogEntries: BlogPost[] | null
}

// Display a section individually
const Section = ({ sectionData, featuredBlogEntries }: Props) => {
  // Prepare the component
  // eslint-disable-next-line no-underscore-dangle
  const SectionComponent = sectionComponents[sectionData.__component] as any;

  if (!SectionComponent) {
    return null;
  }

  // Display the section
  if (featuredBlogEntries) {
    return <SectionComponent data={featuredBlogEntries} />;
  }
  return <SectionComponent data={sectionData} />;
};

const PreviewModeBanner = () => {
  const router = useRouter();

  return (
    <div className="py-4 bg-red-600 text-red-100 font-semibold uppercase tracking-wide">
      <div className="container">
        Preview mode is on.
        {' '}
        <a
          className="underline"
          href={`/api/exit-preview?redirect=${router.asPath}`}
        >
          Turn off
        </a>
      </div>
    </div>
  );
};

interface SectionsProps {
  preview: boolean
  sections: SectionType[]
  featuredBlogEntries: BlogPost[]
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

// Display the list of sections
const Sections = ({ sections, preview, featuredBlogEntries }: SectionsProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* Show a banner if preview mode is on */}
      {preview && <PreviewModeBanner />}
      {/* Show the actual sections */}
      {sections.map((section) => (
        <Section
          sectionData={section}
          featuredBlogEntries={featuredBlogEntries.length > 0 ? featuredBlogEntries : null}
        // eslint-disable-next-line no-underscore-dangle
          key={`${section.__component}${section.id}`}
        />
      ))}
    </div>
  );
};

export default Sections;
