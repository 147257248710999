import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'next-i18next';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme) => ({
  background: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    fontSize: 100,
    animation: '$rotation 3s infinite backwards',
  },
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
  text: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  spinner: {
    color: theme.palette.primary.contrastText,
  },
  div: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const LoadingScreen = () => {
  const classes = useStyles();
  const { t } = useTranslation('components');
  return (
    <div className={classes.background}>
      <Grow in>
        <div className={classes.div}>
          {/* <Brightness5SharpIcon color="secondary" className={classes.icon} /> */}
          <CircularProgress className={classes.spinner} size={75} />
          <Typography
            variant="body1"
            className={classes.text}
          >
            {t('loadingScreen.loading')}
          </Typography>
        </div>
      </Grow>
    </div>
  );
};

export default LoadingScreen;
