import React from 'react';
import TryToAuthorizeView from '@/components/layout/TryToAuthorizeView';

// eslint-disable-next-line react/display-name
const withAuthTry = (Component: React.ComponentType<any>) => {
  const WithAuthTryComponent = (props: JSX.IntrinsicAttributes) => (
    <TryToAuthorizeView>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...props} />
    </TryToAuthorizeView>
  );
  WithAuthTryComponent.displayName = `WithAuthTryComponent(${getDisplayName(Component)})`;
  return WithAuthTryComponent;
};

function getDisplayName(WrappedComponent: React.ComponentType<any>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withAuthTry;
