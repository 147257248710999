/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Link, { LinkProps } from 'next/link';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { CardActionArea } from '@material-ui/core';

export type ButtonLinkProps = Omit<ButtonProps, 'href' | 'classes'> &
Pick<LinkProps, 'href' | 'as' | 'prefetch'>;

interface Props{
}

const defaultProps = {

};

const ButtonLink = React.forwardRef<ButtonLinkProps, any>(
  ({
    href, as, prefetch, ...props
  }: ButtonLinkProps & Props, ref: React.ForwardedRef<any>) => (
    <Link href={href} as={as} prefetch={prefetch} passHref>
      <Button
        ref={ref}
        {...props}
        component="a"
      />
    </Link>
  ),
);

ButtonLink.displayName = 'ButtonLink';

ButtonLink.defaultProps = defaultProps as unknown as Partial<Props>;

export default ButtonLink;
