import React from 'react';
import Markdown from 'react-markdown';

import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import { RichTextSection } from 'typings/pages';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

interface Props {
  data: RichTextSection
}

const defaultProps = {

};

const RichText = ({ data }: Props) => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Markdown>{data.content}</Markdown>
    </Container>
  );
};

RichText.defaultProps = defaultProps as unknown as Partial<Props>;

export default RichText;
