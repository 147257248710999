import React from 'react';
import Head from 'next/head';

import { makeStyles, darken } from '@material-ui/core/styles';
import {
  ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Grid, Container,
} from '@material-ui/core';

// MUI icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ButtonLink from '@/components/page-components/button-link';
import { FAQSection } from 'typings/pages';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  cardheading: {

  },
  heading: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  signupButton: {
    width: '100%',
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: darken(theme.palette.accent.main, 0.3),
    },
  },
  linkButton: {
    color: theme.palette.primary.contrastText,
    borderBottom: 'none',
    width: '100%',
    marginRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: 'transparent',
      borderBottom: 'none',
    },
  },
}));

interface Props {
  data: FAQSection
}

const defaultProps = {

};

const FAQ = ({ data }: Props) => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>

      {/* LD+JSON structured data for google */}
      <Head>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                  ${data.QuestionAndAnswer.map((item) => (
            `{
                        "@type": "Question",
                        "name": "${item.Question}",
                        "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "${item.Answer}"
                        }
                }`
          ))}
              ]
            }
          `}
        </script>
      </Head>

      {/* actual content to display */}
      <Typography
        variant="h3"
        className={classes.heading}
        color="primary"
      >
        Frequently Asked Questions
      </Typography>
      {data.QuestionAndAnswer.map((item) => (
        <ExpansionPanel key={item.id}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6" className={classes.cardheading}>{item.Question}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="stretch"
            >
              <Grid xs={12}>
                <Typography>
                  {item.Answer}
                </Typography>
              </Grid>
              <Grid xs={10} md={3} lg={2}>
                {item.Link
                && (
                  <ButtonLink
                    href={item.Link}
                    variant="contained"
                    className={classes.signupButton}
                    endIcon={<ChevronRightIcon />}
                  >
                    {item.Link}
                  </ButtonLink>
                )}
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </Container>
  );
};

FAQ.defaultProps = defaultProps as unknown as Partial<Props>;

export default FAQ;
