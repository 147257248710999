import React from 'react';
import { NextSeo } from 'next-seo';
import getStrapiMedia from '@/features/pages/utils/media';
import { Metadata } from 'typings/pages';

interface Props {
  metadata: Metadata
}

const defaultProps = {

};

const Seo = ({ metadata }: Props) => {
  // Prevent errors if no metadata was set
  if (!metadata) return null;

  return (
    <NextSeo
      title={metadata.metaTitle}
      description={metadata.metaDescription}
      openGraph={{
        // Title and description are mandatory
        title: metadata.metaTitle,
        description: metadata.metaDescription,
        // Only include OG image if we have it - otherwise we'll include the
        // global share image

        // Careful: if you disable image optimization in Strapi, this will break
        ...(metadata.shareImage && {
          images: Object.values(metadata.shareImage.formats).map((image) => ({
            url: getStrapiMedia(image.url),
            width: image.width,
            height: image.height,
          })),
        }),
      }}
      // Only included Twitter data if we have it
      twitter={{
        ...(metadata.twitterCardType && { cardType: metadata.twitterCardType }),
        ...(metadata.twitterUsername && { cardType: metadata.twitterUsername }),
      }}
    />
  );
};

Seo.defaultProps = defaultProps as unknown as Partial<Props>;

export default Seo;
